.lds-dual-ring {
  position: sticky;
  display: inline-grid;
  top: 50%;
  width: 150px;
  height: 150px;
}
.lds-dual-ring-after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #000;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
