@use 'variables';

.dashboard-step-row {
  padding: 15px;

}

.pending, .complete {
  background-color: $midnightBlueRgb;
  color: white;

  .dashboard-badge {
    color: white;
    background-color: $midnightBlueRgb;
    margin-right: 5px;
  }

  .dashboard-text {
    text-overflow: ellipsis;
    word-break: none;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 0px;
    font-size: 20px;
  }
}

.complete {
  text-decoration: line-through;

  .dashboard-badge {
    text-decoration: line-through;
  }
}

.active, .failed {
  background-color: $midnightBlueRgb;
  color: white;

  .dashboard-badge {
    color: white;
    background-color: $midnightBlueRgb;
    margin-right: 5px;
  }

  .dashboard-text {
    text-overflow: ellipsis;
    word-break: none;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 0px;
    font-size: 20px;
  }
}
