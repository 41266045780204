@use 'variables';

body {
  background: #f9f9f9;
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.card-blue {
  background-color: $midnightBlueRgb;
  color: #fff;
}
.card-green {
  background-color: $appleGreenRgb;
  color: #fff;
}
.tight-card-body {
  padding: 0px;
}
.card-header button {
  font-size: 12px;
}
.left-button-bumper {
  margin-right: 15px;
}
.green-link-button {
  background-color: $appleGreenRgb;
  border-color: $appleGreenRgb;
  min-width: 80px;
  white-space: nowrap;
  font-size: 1em;
}
.green-link-button:hover {
  background-color: $appleGreenRgb;
}
.blue-link-button {
  background-color: $midnightBlueRgb;
  border-color: $midnightBlueRgb;
  min-width: 80px;
  white-space: nowrap;
  font-size: 1em;
}
.blue-link-button:hover {
  background-color: $midnightBlueRgb;
}
.white-blue-link-button {
  background-color: #fcfcfc;
  border-color: #fcfcfc;
  color: $textColor;
  line-height: 10px;
  white-space: nowrap;
  font-size: 1em;
}
.white-blue-link-button:hover {
  background-color: $midnightBlueRgb;
}
.white-green-link-button {
  background-color: #fcfcfc;
  border-color: #fcfcfc;
  color: $textColor;
  line-height: 10px;
  white-space: nowrap;
  font-size: 1em;
}
.white-green-link-button:hover {
  background-color: $midnightBlueRgb;
}
.fa-left-companion {
  padding-right: 5px;
}
.fa-right-companion {
  padding-left: 5px;
}
.return-button-row {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 24px;
}
.custom-modal-header {
  background-color: $midnightBlueRgb;
  color: #fff;
  padding: 15px;
}
.no-padding {
  padding: 0px;
}