@use 'variables';

.navbar-padding {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 15px;
}

.navbar-edit-one {
  border-color: $borderGray;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
}

.nav-button-text-edit {
  font-weight: 10;
  padding: 5px 15px;
  cursor: pointer;
}
