.step-3-submit-button {
  margin-bottom: 15px;
}

.step-3-display-row-header {
  font-weight: bold;
  text-align: center;
  border-bottom-color: rgb(98,144,128);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  white-space:nowrap;
}

.step-3-display-row {
  padding: 10px;
  text-align: center;
  border-bottom-color: rgb(98,144,128);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  white-space:nowrap;
}

.step-3-segment-row {
  white-space:nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;
}

.step-3-label {
  font-weight: bold;
}
