.pdf_paper {
  min-height: 40vw;
  padding: 1em;
}

.accordion-wrapper>div {
  margin: 0 !important;
}

.country_name {
  color: rgb(174, 209, 54);
  font-size: 1.85vw;
  margin: 0;
}

.country_name_longer {
  color: rgb(174, 209, 54);
  font-size: 1.35vw;
  margin: 0;
}

.country_code {
  color: rgb(174, 209, 54);
  font-size: .7vw;
  margin: 0;
}

.risk_rating-high {
  background-color: rgba(255, 0, 0, 0.5);
  font-size: 1.25vw;
  margin: 0;
}

.risk_rating-moderate {
  background-color: yellow;
  font-size: 1.25vw;
  margin: 0;
}

.risk_rating-low {
  background-color: #00FF00;
  font-size: 1.25vw;
  margin: 0;
}

.text_wrapper {
  justify-content: center;
  margin-top: .5em;
}

.risk_text_wrapper {
  text-align: center;
  padding: .5em;
}

.smaller_text {
  font-size: 0.5em;
  margin: 0;
  justify-content: center;
}

.max_width {
  max-width: 24%;
  flex-grow: 0;
  flex-basis: 24%;
}

.attribute_name {
  margin-bottom: .5em;
}

.editor_style {
  width: 100%;
}

.autosave-input-wrapper {
  text-align: center;
}

.autosave-input-wrapper>input,
.autosave-input-wrapper>div>input {
  text-align: center;
  max-width: 100%;
}

.autosave-input-label {
  text-align: center;
  width: 100% !important;
  padding-left: 15px !important;
}

.autosave-different-wrapper>textarea {
  width: 100%;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.editor-wrapper {
  height: 5em
}

.description-map {
  height: 15vw;
}

.title-row {
  background-color: rgb(174, 209, 54);
  color: white;
  margin: 0;
  width: 100%;
  min-height: 1em;
}

.text-margin {
  margin: .1em 1em .1em .7em;
  font-size: .7vw;
  text-align: justify;
}

.text-margin-bin {
  margin: .1em 2em .1em 0;
  font-size: .7vw;
  text-align: right;
}

.text-margin-judgement {
  margin: .1em 0;
  font-size: .75vw;
  text-align: left;
}

.judgement {
  height: 3em;
  text-align: center;
  width: 100%
}

.image {
  width: 100%;
  bottom: 0;
  position: absolute;
}

.image-1 {
  width: 100%;
  bottom: 2em;
  position: absolute;
}

.no-padding-card {
  padding: 0;
}

.positioning {
  position: relative;
  margin: auto;
}

.positioning-copyright {
  position: relative;
  margin: auto;
  text-align: center;
}

.country_description {
  margin: 0.5em 1em;
  text-align: justify;
  font-size: 0.68vw;
}

.country_map {
  margin: .5em 0;
  width: 100%
}

.admin-input-title>input {
  width: 100%;
}

.bigger_text {
  font-size: 1.5vw;
  margin-bottom: 0.5em;
  margin-top: 0;
}

.fixes {
  height: 0;
}

.big_top_margin {
  position: absolute;
  bottom: 0;
}

.copyright_text>p {
  text-align: center;
  font-size: .5vw;
}

.description_wrapper {
  height: 13.7vw;
}

.source_wrapper {
  height: 1em;
}

.shading {
  background-color: rgba(217, 215, 225, 0.22);
}