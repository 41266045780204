@use 'variables';

.Dropzone {
  height: 200px;
  width: 200px;
  background-color: $backgroundGrayHex;
  border: 2px dashed $borderGray;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
}

.Icon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
}

.FileInput {
  display: none;
}

.Highlight {
  background-color: rgb(188, 185, 236);
}