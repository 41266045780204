@use 'variables';

.ProgressBar {
  width: 100%;
  height: 8px;
  background-color: $backgroundGrayRgb;
  border-radius: 5px;
  border-color: $borderGray;
  border-width: 1px;
  border-style: solid;
}

.Progress {
  background-color: $midnightBlueRgb;
  height: 100%;
  margin: 0;
  border-radius: 5px;
}