.step-two-row {
  .tab-content {
    width: 100%;
  }
}

.step-two-tabs {
  padding-left: 10px;
}

.step-two-tab {
  border: 1px solid black;
}

.step-two-tabs {
  .nav-item {
    background:white;
    margin-bottom: 10px;    
  }

  .nav-item.active {
    background:rgb(93,135,161);
    color: white;
  }

}

.step-two-check {
  font-size: 20px;
}

.step-two-x {
  font-size: 20px;
  color: red;
}

.step-two-form-group label {
  padding-left: 15px;
  padding-top: 10px;
}

.step-two-form-group {
  margin-bottom: 0px;
}

.save-schema-button {
  margin-top:5px;
  margin-right: 5px;
}

.step-two-form-group-header {
  background-color: rgb(98,144,128);
  margin-bottom: 0px;
  padding: 5px;
  font-weight:bold;
}