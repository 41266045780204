$primary: #629383;

$input-btn-border-width: 2px;
$btn-font-weight: 700;
$btn-border-radius: 0.45rem;
$btn-border-radius-lg: 0.5rem;
$btn-border-radius-sm: 0.4rem;

//new variables

$charcoalGreyHex: #3c3c3c;
$charcoalGreyRgb: rgb(60,60,60);

$midnightBlueHex: #1d4c7e;
$midnightBlueRgb: rgb(30,76,126);

$appleGreenHex: #aed136;
$appleGreenRgb: rgb(174,209,54);

$backgroundGrayHex: #f9f9f9;
$backgroundGrayRgb: rgb(249,249,249);

$borderGray: #bdbdbd;

$textColor: #000;
