@use 'variables';

.loading-screen-spinner {
    margin-top: 50px;
}

.loading-screen-spinner .spinner-border {
    width: 7em;
    height: 7em;
    text-align: center;
    color: $borderGray;
}

.loading-screen-text {
    font-weight:bold;
    font-size: 30px;
    text-align: center;
    color: rgb(98,144,128);
}