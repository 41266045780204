.building-screen-spinner {
  margin-top: 50px;
}

.building-screen-spinner .spinner-border {
  width: 7em;
  height: 7em;
  text-align: center;
  color: white;
}

.building-screen-text {
  font-weight:bold;
  font-size: 30px;
  text-align: center;
  color: white;
}